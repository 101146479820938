import React, { ReactNode } from 'react';

import { ReactComponent as LogoSVG } from 'assets/intro.svg';
import IconSvg from 'components/ui/IconSvg';

import {
  Wrapper,
  IntroBox,
  IntroContent,
  PageContent,
} from './styled';

interface Props {
  children: ReactNode;
}

const AuthenticationWrapper = ({ children }: Props) => (
  <Wrapper>
    <IntroBox>
      <IconSvg Icon={LogoSVG} />
    </IntroBox>

    <IntroContent>
      <PageContent>
        <>{children}</>
      </PageContent>
    </IntroContent>
  </Wrapper>
);

export default AuthenticationWrapper;
