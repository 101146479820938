import React, { ReactNode, useState, useEffect } from 'react';
import { logout } from 'api/authService';
import { LANDING } from 'constants/routes';
import { useUser } from 'api/userService';

import { ReactComponent as LogoSVGDesktop } from 'assets/Icon/logo.svg';
import { ReactComponent as LogoSVGMobile } from 'assets/Icon/mobile_logo.svg';
import IconSvg from 'components/ui/IconSvg';
import Avatar from 'components/ui/Avatar';
import BannerSponsor from 'components/ui/BannerSponsor';
import Menu from 'components/ui/Menu';

import { useTournament } from 'context/TournamentContext';
import * as routes from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { getCookieDomainFromUrl } from 'utils';

import {
  Wrapper,
  Nav,
  ColLeft,
  ColRight,
  Col,
  BtnLogo,
  BtnLogout,
  BtnLogIn,
  IconLogOut,
  PageContent,
} from './styled';

interface Props {
  children: ReactNode;
}

const StatusWrapper = ({ children }: Props) => {
  const { isLoggedIn } = useUser();
  const { tournamentDetails } = useTournament();
  const tournamentId = tournamentDetails?.data?.id;
  const [isload, setLoad] = useState(false);
  const domain = getCookieDomainFromUrl(process.env.REACT_APP_LANDING_PAGE);
  const navigate = useNavigate();
  const [isAnonymousUser, setAnonymousUser] = useState(false);
  const tournamentAnonymousUsers = tournamentDetails?.data?.attributes?.allow_anonymous_users;

  useEffect(() => {
    if (tournamentAnonymousUsers === false ) {
      setAnonymousUser(false);
    }
    if (tournamentAnonymousUsers === true ) {
      setAnonymousUser(true);
    }
  });

  console.log(tournamentAnonymousUsers);

  const handleLogInClick = async () => {
    try {
      setLoad(!isload);
      if (!isLoggedIn) {
        localStorage.setItem('intendToJoinTournament', tournamentId);
        Cookies.set('intendToJoinTournament', tournamentId, { expires: 7,secure: true, sameSite: 'none', domain });
        navigate(routes.HOME);
        return;
      }

      const newPath = `${routes.GAME.replace(':id', tournamentId)}`;
      window.location.href = newPath;

    } catch (error) {
      if (error.error.status === 409 && error.error.name === 'ConflictError') {
        window.location.href = `${routes.GAME.replace(':id', tournamentId)}`;
      } else {
        console.error('Error joining tournament:', error);
      }
    }
  };

  const handleLogoutClick = () => {
    logout();
  };

  return (
    <Wrapper>
      <Nav>
        <ColLeft>
          <Col>
            <BtnLogo to={LANDING}>
              <IconSvg className="svgDesktop" width={140} Icon={LogoSVGDesktop} />
              <IconSvg className="svgMobile"  width={65} Icon={LogoSVGMobile} />
            </BtnLogo>
            {isLoggedIn &&<Avatar />}
          </Col>
          <Col className="center">
            <BannerSponsor />
          </Col>
          <Col className="menuCol">
            <div className="menuWrap">
              <Menu />
            </div>
            {!isLoggedIn ? (
              <BtnLogIn className="btnLog" onClick={handleLogInClick}>Log in <IconLogOut></IconLogOut></BtnLogIn>
            ) :
              <BtnLogout className="btnLog" onClick={handleLogoutClick} to={LANDING}>Logout <IconLogOut></IconLogOut></BtnLogout>
            }
          </Col>
        </ColLeft>
        {!isAnonymousUser ? (
          <ColRight>
            {!isLoggedIn ? (
              <BtnLogIn className="btnLog" onClick={handleLogInClick}>Log in <IconLogOut></IconLogOut></BtnLogIn>
            ) :
              <BtnLogout className="btnLog" onClick={handleLogoutClick} to={LANDING}>Logout <IconLogOut></IconLogOut></BtnLogout>
            }
          </ColRight>
        ): null }
      </Nav>
      <PageContent>
        <>{children}</>
      </PageContent>
    </Wrapper>
  );
}

export default StatusWrapper;
