import React, { useState } from 'react';

import {
  PopupWrap,
  CloseWrap,
} from './styled';

const PopupInfo = ({text, subText, subTextSecond, className, handleClose}) => {
  return (
    <PopupWrap className={className}>
      <CloseWrap onClick={()=> handleClose()} />
      <p>{text}</p>
      <p>{subText}</p>
      <p>{subTextSecond}</p>
    </PopupWrap>
  );
};

export default PopupInfo;
