import styled from 'styles/styled';
import { StyledLink } from 'components/ui/StyledLink/StyledLink';

export const TabBtnMenu = styled(StyledLink)`
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  color: #454545;
  min-width: 124px;
  background-color: #090B0E;
  margin-left: 10px;
  text-align: center;
  padding: 2px 10px 0 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: block;
  &:hover {
    color: #EFEFEF;
    opacity: 1;
  }
  &.Active {
    color: ${({ theme }) => theme.palette.primary};
    background-color: #15191E;
    &:hover {
      color: #EFEFEF;
    }
  }
`;

export const MenuBottom = styled.div`
  margin-right: 24px;
  display: flex;
`;
