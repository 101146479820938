import React, { useState } from 'react';
import ModalLargeInfoBase from 'components/ui/ModalLargeInfoBase';
import Cookies from 'js-cookie';
import { ButtonFilled } from 'components/ui/Button';

import {
  Close,
  WrapModal,
  TitleModal,
  UserModal,
  Avatar,
  TextModal,
  Tab,
  ButtonTab,
  StyledInput,
  ContentModal,
  Row,
} from './styled';

const ModalProfil = ({handleClose}) => {
  const username = localStorage.getItem('username') ?? Cookies.get('username');
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTab1 = () => { setActiveTab("tab1"); };
  const handleTab2 = () => { setActiveTab("tab2"); };

  return (
    <>
      <ModalLargeInfoBase>
        <Close onClick={()=> handleClose()}/>
        <WrapModal className="detailWrapModal">
          <TitleModal>Edit profile</TitleModal>
          <UserModal>{username}</UserModal>
          <Avatar className="avatarModal" />
        </WrapModal>
        <WrapModal>
          <TextModal className="inner">Change</TextModal>
          <Tab>
            <ButtonTab className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>Nickname</ButtonTab>
            <ButtonTab className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>Password</ButtonTab>
          </Tab>

          {activeTab === "tab1" ?
            <>
              <TextModal>Enter new Nickname for your account</TextModal>
              <ContentModal>
                <StyledInput
                  label="New Nickname"
                />
              </ContentModal>
              <Row>
                <ButtonFilled className="outlineColor" onClick={()=> handleClose()}>Cancel</ButtonFilled>
                <ButtonFilled>Accept</ButtonFilled>
              </Row>
            </>
          : null }

          {activeTab === "tab2" ?
          <>
            <TextModal>Enter new Password for your account</TextModal>
            <ContentModal>
              <StyledInput
                label="New Password"
              />
              <StyledInput
                label="Confirm Password"
              />
            </ContentModal>
            <Row>
              <ButtonFilled className="outlineColor" onClick={()=> handleClose()}>Cancel</ButtonFilled>
              <ButtonFilled>Accept</ButtonFilled>
            </Row>
          </>
          : null }
        </WrapModal>
      </ModalLargeInfoBase>
    </>
  );
};

export default ModalProfil;
