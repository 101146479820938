import React, { ReactNode } from 'react';

import {
  Wrap,
  WrapCenter,
} from './styled';

interface Props {
  children: ReactNode;
}

const ModalSmallInfoBase = ({ children }: Props) => {
  return (
        <Wrap>
          <div>
            <WrapCenter className="noBg">
              <>{children}</>
            </WrapCenter>
          </div>
        </Wrap>
  );
};

export default ModalSmallInfoBase;
