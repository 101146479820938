import React, { useEffect, useState, useContext } from 'react';
import { requestTradingData } from 'api/socketService';
import { useWalletData } from 'context/WalletDataContext';
import { useTradeUpdate } from 'context/TradeUpdateContext';
import { formatCurrency } from 'utils';
import SocketContext from 'utils/hooks/useSocket';

import {
  Wrap,
  Td,
  Th,
  Table,
  Tab,
  Button,
} from './styled';

type Trade = {
  id: number;
  trading_pair: string;
  amount: number;
  price: number;
  market_value: number;
  type: string;
  createdAt: string;
  updatedAt: string;
};

const TradeHistory = (props: { tournamentDetails: any; }) => {
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTab1 = () => { setActiveTab("tab1"); };
  const handleTab2 = () => { setActiveTab("tab2"); };
  const { tournamentDetails } = props;
  const [tradingData, setTradingData] = useState<Trade[]>([]);
  const { walletData } = useWalletData();
  const { tradeUpdated } = useTradeUpdate();
  const {allCoinsValues } = useContext(SocketContext);

  function calculatePerformance(data, selectedCoinPrice) {
    let totalCoins = 0;
    let totalCost = 0;
    let weightedPriceSum = 0
    let totalAmountBought = 0;

    data?.forEach(trade => {
      if (trade.type === 'buy') {
        totalCoins += trade.amount;
        totalCost += trade.price;
        weightedPriceSum += trade.amount * trade.market_value;
        totalAmountBought += trade.amount;
      } else if (trade.type === 'sell') {
        totalCoins -= trade.amount;
        totalCost -= trade.price;
      }
    });

    const avgPrice = weightedPriceSum / totalAmountBought;
    const currentValue = totalCoins * selectedCoinPrice; // Current total value in USDT
    const plUSDT = currentValue - totalCost
    const plPercent = (currentValue*100 / totalCost) - 100;

    return {
      totalCoins: totalCoins,
      totalCost: totalCost,
      avgPrice: avgPrice,
      currentValue: currentValue,
      plUSDT: plUSDT,
      plPercent: plPercent
    };
  }

  const updateTrading = (data) => {
    if (data.success) {
      setTradingData(data.data.trading);

    } else {
      console.error('Error fetching trading data:');
    }
  };

  const fetchTradingData = () => {
    const tournamentId = tournamentDetails?.data?.id;
    const tradeType = tournamentDetails?.data?.attributes?.trade_type;
    if (tournamentId && tradeType) {
      requestTradingData(tournamentId, tradeType, updateTrading);
    }
  };

  const [pairTradesData, updatePairTradesData] = useState(null)
  const [currentWalletData, updateCurrentWalletData] = useState(null)

  if(walletData?.length){
    if(currentWalletData?.length){
      walletData.forEach((element, i) => {
        if(element?.amount !== currentWalletData[i]?.amount){
          updateCurrentWalletData(walletData)
        }
      });
    }else{
      updateCurrentWalletData(walletData)
    }
  }

  useEffect(()=>{
    fetchTradingData();
  }, [tradeUpdated])

  let tradingPairWithAmount = walletData.filter(obj => obj.amount > 0 && obj.ticker.short_name !== 'USDT');

  useEffect(() => {
    const pairTradesDataTemp = {};
    const pairTradesGroups = {};
    tradingData.forEach(trade => {
      const pair = trade.trading_pair;
      if (!pairTradesGroups[pair]) {
        pairTradesGroups[pair] = [];
      }
      pairTradesGroups[pair].push(trade);
      for (let key in pairTradesGroups) {
        let shortName = key.split('/')[0].toLowerCase();
        let pair = pairTradesGroups[key]
        if(allCoinsValues && allCoinsValues[shortName]){
          let data = calculatePerformance(pair, allCoinsValues[shortName].last);
          pairTradesDataTemp[key] = data
        }
      }
  });
  updatePairTradesData(pairTradesDataTemp)
}, [allCoinsValues, tradingData]);

  return (
    <Wrap>
      <Tab>
        <Button className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>Trade History</Button>
        <Button className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>Asset Performance</Button>
      </Tab>

      {activeTab === "tab1" ?

        <>
          <Table>
            <thead>
              <tr>
                <Th>Trading pair</Th>
                <Th>Amount</Th>
                <Th>Price (USDT)</Th>
                <Th>Value (USDT)</Th>
                <Th>Type</Th>
                <Th>Time (UTC)</Th>
              </tr>
            </thead>
            <tbody>
              {tradingData.map(trade => (
                <tr key={trade.id}>
                  <Td>{trade.trading_pair}</Td>
                  <Td>{trade.amount.toFixed(8)}</Td>
                  <Td>{formatCurrency(trade.market_value)}</Td>
                  <Td>{formatCurrency(trade.price)}</Td>
                  <Td className={trade.type === 'buy' ? 'up_state' : 'down_state'}>
                    {trade.type.charAt(0).toUpperCase() + trade.type.slice(1).toLowerCase()}
                  </Td>
                  <Td>{new Date(trade.createdAt).toLocaleString()}</Td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>

        :

        <>
          <Table>
            <thead>
              <tr>
                <Th>Asset</Th>
                <Th>Amount</Th>
                <Th>AVG Price (USDT)</Th>
                <Th>P/L USDT</Th>
                <Th>P/L%</Th>
                <Th></Th>
              </tr>
            </thead>
            <tbody>
              {tradingPairWithAmount.map(pair => (
                <tr key={pair.id}>
                  <Td>{formatCurrency(pairTradesData[`${pair.ticker.short_name}/USDT`]?.currentValue)} USDT</Td>
                  <Td>{pairTradesData[`${pair.ticker.short_name}/USDT`]?.totalCoins.toFixed(8)} {pair.ticker.short_name}</Td>
                  <Td>{formatCurrency(pairTradesData[`${pair.ticker.short_name}/USDT`]?.avgPrice.toFixed(2))} USDT</Td>
                  <Td className={pairTradesData[`${pair.ticker.short_name}/USDT`]?.plPercent >= 0 ? 'up_state' : 'down_state'}>
                    {formatCurrency(pairTradesData[`${pair.ticker.short_name}/USDT`]?.plUSDT)} USDT
                  </Td>
                  <Td className={pairTradesData[`${pair.ticker.short_name}/USDT`]?.plPercent >= 0 ? 'up_state' : 'down_state'}>
                    {pairTradesData[`${pair.ticker.short_name}/USDT`]?.plPercent.toFixed(2)}%
                  </Td>
                  <Td></Td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>

      }
    </Wrap>
  );
};
export default TradeHistory;
