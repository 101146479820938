import styled from 'styles/styled';
import iconClose from '../../../assets/Icon/icon_close.svg';
import iconAvatar from '../../../assets/icons/avatarP.jpg';
import Input from 'components/ui/Input';

export const Close = styled.div`
  background-image: url(${iconClose});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -56px;
  color: #fff;
  width: 40px;
  height: 40px;
  background-color: #454545;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 100ms linear;
  &:hover {
    opacity: 0.8;
  }
`;

export const WrapModal = styled.div`
  background: #15191E;
  border-radius: 14px;
  width: 100%;
  min-height: 20px;
  position: relative;
  padding: 11px 16px 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.detailWrapModal {
    padding-bottom: 30px;
    &:after {
      background-color: #292B2E;
      width: calc(100% - 14px);
      height: 2px;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      margin: 0 auto;
      content: " ";
    }
  }
`;

export const Avatar = styled.div`
  width: 86px;
  height: 86px;
  border-radius: 100%;
  background-image: url(${iconAvatar});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;


export const UserModal = styled.h4`
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #efefef;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
`;

export const TitleModal = styled.h2`
  color: #efefef;
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
`;

export const TextModal = styled.p`
  color: #efefef;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 3px;
  &.inner {
    color: #A0A0A0;
    font-weight: 400;
    margin-bottom: 3px;
  }
`;

export const Tab = styled.div`
  border-radius: 14px;
  width: 100%;
  background-color: #292B2E;
  display: flex;
  margin-bottom: 4px;
`;

export const ButtonTab = styled.div`
  background-color: transparent;
  border-radius: 14px;
  color: #A0A0A0;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  width: 50%;
  padding: 12px 0;
  text-align: center;
  cursor: pointer;
  &.active {
    background: #52D381;
    color: #0A0A0A;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  button {
    width: calc(50% - 3px);
    border: 1px solid #52D381;
    &.outlineColor {
      background-color: transparent;
      border: 1px solid #52D381;
      color: #52D381;
    }
  }
`;

export const ContentModal = styled.div`
  min-height: 180px;
  width: 100%;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  margin-bottom: 5px;
`;
