import React, { useState } from 'react';
import { ButtonFilled } from 'components/ui/Button';
import { ReactComponent as TournamentSVG } from 'assets/intro.svg';
import IconSvg from 'components/ui/IconSvg';

import {
  Wrap,
  Ul,
  Li,
  Box,
  DetailInfo,
  ColLeft,
  ColRight,
  Row,
  BoxPhoto,
  BoxContent,
  Title,
  Status,
  Date,
  TournamentPhoto,
  BoxStatus,
  StatusOnline,
  TagText,
  BoxTag,
  Info,
  SmallText,
  Tab,
  ButtonTab,
} from './styled';

const ListTournaments = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTab1 = () => { setActiveTab("tab1"); };
  const handleTab2 = () => { setActiveTab("tab2"); };
  const handleTab3 = () => { setActiveTab("tab3"); };

  return (
    <Wrap>

      <Tab>
        <ButtonTab className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>My</ButtonTab>
        <ButtonTab className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>All</ButtonTab>
        <ButtonTab className={activeTab === "tab3" ? "active" : ""} onClick={handleTab3}>Past</ButtonTab>
      </Tab>

      <SmallText>Result / Position</SmallText>
      {activeTab === "tab1" ?
        <Ul>
          <Li className="is_your">
            <DetailInfo>Your tournament</DetailInfo>
            <Box>
              <ColLeft>
                <Row>
                  <BoxPhoto>
                    <TournamentPhoto>
                      <IconSvg Icon={TournamentSVG} />
                    </TournamentPhoto>
                    <Status className="active">Live</Status>
                  </BoxPhoto>
                  <BoxContent>
                    <Date>19 August 2024, Monday, 4:00 PM (CET)</Date>
                    <Title>Player1234 Crypto Tournament</Title>
                  </BoxContent>
                </Row>

                <Row>
                  <BoxStatus>
                    <StatusOnline><span>2</span>online</StatusOnline>
                  </BoxStatus>
                  <BoxTag>
                    <TagText><span>8</span> participants</TagText>
                    <TagText><span>4</span> active players</TagText>
                    <TagText><span>50 USDT</span> in reward pool</TagText>
                  </BoxTag>
                </Row>
              </ColLeft>
              <ColRight>
                <Info>- / -</Info>
                <ButtonFilled>Play</ButtonFilled>
              </ColRight>
            </Box>
          </Li>
        </Ul>
      : null }

      {activeTab === "tab2" ?
        <Ul>
          <Li>
            <DetailInfo>Your tournament</DetailInfo>
            <Box>
              <ColLeft>
                <Row>
                  <BoxPhoto>
                    <TournamentPhoto>
                      <IconSvg Icon={TournamentSVG} />
                    </TournamentPhoto>
                    <Status>Live</Status>
                  </BoxPhoto>
                  <BoxContent>
                    <Date>19 August 2024, Monday, 4:00 PM (CET)</Date>
                    <Title>Player1234 Crypto Tournament</Title>
                  </BoxContent>
                </Row>

                <Row>
                  <BoxStatus>
                    <StatusOnline><span>2</span>online</StatusOnline>
                  </BoxStatus>
                  <BoxTag>
                    <TagText><span>8</span> participants</TagText>
                    <TagText><span>4</span> active players</TagText>
                    <TagText><span>50 USDT</span> in reward pool</TagText>
                  </BoxTag>
                </Row>
              </ColLeft>
              <ColRight>
                <Info>- / -</Info>
                <ButtonFilled>Join</ButtonFilled>
              </ColRight>
            </Box>
          </Li>
        </Ul>
      : null }

      {activeTab === "tab3" ?
        <p>tab 3 </p>
      : null }

    </Wrap>
  );
};

export default ListTournaments;
