import styled from 'styles/styled';
import imgChartContainer from '../../../assets/ChartContainer.svg';
import imgbull from '../../../assets/bullBg.png';
import iconEdit from '../../../assets/icons/editIcon.svg';
import iconAvatar from '../../../assets/icons/avatarP.jpg';
import iconAdd from '../../../assets/icons/add-line.svg';
import navDetail from '../../../assets/shadow.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProfileWrap = styled.div`
  background: linear-gradient(224.78deg, #15191E 45.48%, #000000 78.16%);
  width: 100%;
  border-radius: 10px;
  padding: 16px 24px 0 24px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
  &:after {
    content: " ";
    background-image: url(${imgChartContainer});
    width: 100%;
    height: 150px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-position: right bottom;
    background-repeat: no-repeat;
  }
  &:before {
    content: " ";
    background-image: url(${imgbull});
    width: 100%;
    height: 150px;
    position: absolute;
    background-size: contain;
    left: 0;
    bottom: 0;
    background-position: -40px bottom;
    background-repeat: no-repeat;
  }
  .noBg {
    background-color: transparent;
  }
  .carousel-slider {
    height: 215px;
    z-index: 10;
    .carousel-status {
      display: none;
    }

    .carousel.carousel-slider,
    .control-arrow:hover {
      background-color: #292B2E;
    }

    .carousel.carousel-slider,
    .control-arrow {
      background-color: #292B2E;
      border-radius: 27px;
      height: 48px;
      width: 24px;
      margin-top: 40px;
      padding: 0;
      opacity: 1;
    }
  }
`;

export const IconEdit = styled.span`
  background-image: url(${iconEdit});
  width: 14px;
  height: 14px;
  background-size: contain;
  background-position: center;
  display: block;
  background-repeat: no-repeat;
  margin-right: 4px;
`;

export const BeltTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
`;

export const Avatar = styled.div`
  width: 76px;
  height: 76px;
  border-radius: 100%;
  background-color: #ddd;
  margin-right: 12px;
  background-image: url(${iconAvatar});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ColLeft = styled.div`
  display: flex;
`;

export const Btn = styled.a`
  color: #EFEFEF;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  background: #292B2E;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  cursor: pointer;
`;

export const UserName = styled.h2`
  font-size: 16px;
  font-weight: 500;
  color: #EFEFEF;
  line-height: 18px;
`;

export const TextSmall = styled.p`
  font-size: 9px;
  font-weight: 400;
  line-height: 18px;
  color: #696969;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h3`
  font-size: 15px;
  font-weight: 500;
  color: #A0A0A0;
  line-height: 20px;
  span {
    display: block;
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const Number = styled.h2`
  font-size: 36px;
  font-weight: 600;
  line-height: 54px;
  text-align: center;
  color: #52D381;
  margin-bottom: 2px;
`;


export const Text = styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  color: #A0A0A0;
  margin-bottom: 27px;
  span {
    color: #EFEFEF;
    font-weight: 700;
  }
`;

export const Legend = styled.p`
  background: #A0A0A0;
  color: #000000;
  font-size: 11px;
  font-weight: 400;
  line-height: 8px;
  text-align: center;
  border-radius: 7px;
  padding: 5px 13px;
  display: table;
  margin: 0 auto;
`;

export const IconAdd = styled.span`
  width: 10px;
  background-image: url(${iconAdd});
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 12px;
`;

export const Tab = styled.a`
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  min-width: 124px;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #52D381;
  background-color: #15191E;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RowBelt = styled.div`
  margin-top: 8px;
  background-size: 430px;
  background-position: center -20px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  @media (max-width: 720px) {
    background-image: url(${navDetail});
  }
`;

export const Content = styled.div`
  background-color: #15191E;
  min-height: calc(100vh - 500px);
  width: 100%;
  border-radius: 10px;
  border-top-left-radius: 0;
`;
