import React, { ReactNode } from 'react';

import BgDesktopEnd from '../../../assets/PageDetail/fullbodyEnd.png';
import BgMobileEnd from '../../../assets/PageDetail/fullbodyEndMobile.png';

import {
  Wrap,
  TopRow,
  ContentRow,
  RowWrap,
} from './styled';

interface Props {
  children: ReactNode;
}

const EndWrapper = ({ children }: Props) => {
  return (
    <>
      <Wrap>
        <RowWrap>
          <TopRow>
            <img src={BgDesktopEnd} className="DesktopImg" alt="End img" />
            <img src={BgMobileEnd} className="MobileImg" alt="End img" />
          </TopRow>
          <ContentRow>
            <>{children}</>
          </ContentRow>
        </RowWrap>
      </Wrap>
    </>
  );
};

export default EndWrapper;
