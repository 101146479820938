import React, { useState, useEffect } from 'react';
import { PROFILE } from 'constants/routes';
import {  useLocation } from "react-router-dom";
import { useUser } from 'api/userService';

import {
  MenuBottom,
  TabBtnMenu,
} from './styled';

const Menu = () => {
  let location = useLocation();
  const { isLoggedIn } = useUser();

  return (
    <MenuBottom className="MenuWrap">
      <TabBtnMenu to={'https://tradingbattles.com/#upcoming-tournaments'}>Game</TabBtnMenu>
      {/*
        {isLoggedIn &&
          <TabBtnMenu className={`nav-link ${location.pathname === "/profile" ? "Active" : ""}`} to={PROFILE}>Profile</TabBtnMenu>
        }
      */}
    </MenuBottom>
  );
};

export default Menu;
