import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as routes from 'constants/routes';
import { ButtonFilled } from 'components/ui/Button';
import Modal from 'components/layout/Modal';
import TermConsent from 'components/TermConsent';
import TournamentCountdown from 'components/game/TournamentCountdown';
import { joinTournament } from 'api/tournamentService';
import { useTournament } from 'context/TournamentContext';
import { useUser } from 'api/userService';
import Cookies from 'js-cookie';
import { getCookieDomainFromUrl } from 'utils';
import PopupImg from '../../../assets/blackfort-popup.png';
import { registerAnonymousUser } from 'utils/auth/anonymousRegistration';

import {
  Title,
  RowWrap,
  Row,
  Time,
  Text,
  Amount,
  StatePpl,
  Load,
  LoadWrap,
  TextSub,
  ContentCheckbox,
  RowCheckbox,
  CloseModal,
  TimeText,
  ModalSimple,
  ClosePopup,
  ContentModalSimple,
  ModalBackground,
} from './styled';

const JoinPage = (props: { tournamentDetails: any; }) => {
  const [isOpenedModal, setIsOpenedModal] = useState(false);
  const [isOpenedPopup, setIsOpenedPopup] = useState(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const [agree, setAgree] = useState(false);
  const [isload, setLoad] = useState(false);
  const [isAnonymousUser, setAnonymousUser] = useState(false);
  const { isLoggedIn } = useUser();
  const { tournamentDetails } = props;
  const { tournamentStatus, joinedStatus } = useTournament();
  const navigate = useNavigate();
  const currentDate = new Date(); // get the current date
  const startDate = new Date(tournamentDetails?.data?.attributes?.start_at);
  const endDate = new Date(tournamentDetails?.data?.attributes?.end_at);
  const domain = getCookieDomainFromUrl(process.env.REACT_APP_LANDING_PAGE);
  let timeLabel = "starts in";
  let countdownDate = startDate;
  const rewardPool = tournamentDetails?.data?.attributes?.reward_pool;
  const rewardPoolCurrency = tournamentDetails?.data?.attributes?.reward_pool_currency || 'USDT';
  const displayRewardPool = rewardPool > 0 ? rewardPool + ' ' + rewardPoolCurrency : '---';
  const numberOfParticipants = tournamentDetails?.data?.attributes?.participants?.data?.length || 0;
  const tournamentId = tournamentDetails?.data?.id;
  const tournamentAnonymousUsers = tournamentDetails?.data?.attributes?.allow_anonymous_users;

  const checkboxHandler = () => {
    if (ref1.current.checked && ref2.current.checked && ref3.current.checked) {
      setAgree(!agree);
    } else {
      setAgree(false);
    }
  }

  if (startDate < currentDate) {
    timeLabel = "ends in";
    countdownDate = endDate;
  }

  let loadTime;
  if (!tournamentDetails || isNaN(countdownDate.getTime())) {
    loadTime = <Load><LoadWrap></LoadWrap></Load>;
  } else {
    loadTime = <TournamentCountdown date={countdownDate.toISOString()} />;
  }

  const handleLogInClick = async () => {
    try {
      setLoad(!isload);
      if (!isLoggedIn) {
        localStorage.setItem('intendToJoinTournament', tournamentId);
        Cookies.set('intendToJoinTournament', tournamentId, { expires: 7, secure: true, sameSite: 'none', domain });
        setAgree(false);
        navigate(routes.HOME);
        return;
      }

      const newPath = `${routes.GAME.replace(':id', tournamentId)}`;
      window.location.href = newPath;

    } catch (error) {
      if (error.error.status === 409 && error.error.name === 'ConflictError') {
        window.location.href = `${routes.GAME.replace(':id', tournamentId)}`;
      } else {
        console.error('Error joining tournament:', error);
      }
    }
  };

  const handleJoinClick = async () => {
    try {
      setLoad(!isload);
      const hash = new URLSearchParams(window.location.search).get('hash');
      
      if (!isLoggedIn) {
        localStorage.setItem('intendToJoinTournament', tournamentId);
        Cookies.set('intendToJoinTournament', tournamentId, { expires: 7, secure: true, sameSite: 'none', domain });
        setAgree(false);
        navigate(routes.HOME);
        return;
      }

      if (!joinedStatus) {
        await joinTournament(tournamentId, hash);
      }

      const newPath = `${routes.GAME.replace(':id', tournamentId)}`;
      window.location.href = newPath;

    } catch (error: any) {
      setLoad(false);
      if (error?.error?.status === 409 && error?.error?.name === 'ConflictError') {
        window.location.href = `${routes.GAME.replace(':id', tournamentId)}`;
      } else if (error?.error?.status === 403 && error?.error?.name === 'ForbiddenError') {
        alert('Auto-generated users are not allowed in this tournament. Logout and try again.');
      } else {
        console.error('Error joining tournament:', error);
        alert('An error occurred while joining the tournament. Please try again.');
      }
    }
  };

  const handleJoinClickRandomUser = async (event?: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    try {
      const hash = new URLSearchParams(window.location.search).get('hash');
      
      // Register anonymous user and store credentials
      const credentials = await registerAnonymousUser();
      
      // Store user information
      localStorage.setItem('username', credentials.username);
      localStorage.setItem('intendToJoinTournament', tournamentId);
      localStorage.setItem('email', credentials.email);
  
      // Join tournament if not already joined
      if (!joinedStatus) {
        await joinTournament(tournamentId, hash);
      }
  
      // Navigate to game
      const gamePath = routes.GAME.replace(':id', tournamentId);
      window.location.href = gamePath;
  
    } catch (error: any) {
      console.error('Error joining tournament:', error);
      setLoad(false);
      if (error?.error?.status === 409 && error?.error?.name === 'ConflictError') {
        // User already in tournament, redirect to game
        const gamePath = routes.GAME.replace(':id', tournamentId);
        window.location.href = gamePath;
      } else {
        alert('An error occurred while joining the tournament. Please try again.');
      }
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const autoJoin = searchParams.get('autojoin');
    
    if (tournamentId === 122) {
      setIsOpenedPopup(true);
    }
    if (tournamentAnonymousUsers === false) {
      setAnonymousUser(false);
    }
    if (tournamentAnonymousUsers === true) {
      setAnonymousUser(true);
      // Automatically join if autojoin=true and it's a random user tournament
      if (autoJoin === 'true' && !joinedStatus && (tournamentStatus === 'IN_PROGRESS' || tournamentStatus === 'BEFORE_START')) {
        handleJoinClickRandomUser();
      }
    }
  }, [tournamentId, tournamentAnonymousUsers, joinedStatus, tournamentStatus]);

  return (
    <div>
      <Title>
        {tournamentDetails?.data?.attributes?.name}
        {(tournamentStatus === 'IN_PROGRESS' || tournamentStatus === 'FINISHED') ? (
          <span></span>
        ) : <span>{timeLabel}</span>}
      </Title>
      <RowWrap>
        {joinedStatus && (tournamentStatus === 'FINISHED') ? (
          <Time><TimeText>Finished</TimeText></Time>
        ) : <Time>{loadTime}</Time>}
        <Row>
          <div>
            <Text>Reward pool</Text>
            <Amount>{displayRewardPool}</Amount>
          </div>
          <div>
            <Text>Participants</Text>
            <StatePpl><b>{numberOfParticipants}</b>/No limits</StatePpl>
          </div>
        </Row>

        {!joinedStatus && (tournamentStatus === 'IN_PROGRESS' || tournamentStatus === 'BEFORE_START') ? (
          <>
            {isAnonymousUser ? (
              <>
                <ButtonFilled type="submit" onClick={handleJoinClickRandomUser}>
                  Join
                </ButtonFilled>
                <TextSub>
                  Or choose other tournaments <Link to={'https://tradingbattles.com/#upcoming-tournaments'}>here</Link>
                </TextSub>
              </>
            ) :

              <div>
                {!isLoggedIn && (tournamentStatus === 'IN_PROGRESS' || tournamentStatus === 'BEFORE_START') ? (
                  <>
                    <ButtonFilled type="submit" onClick={handleLogInClick}>
                      Log in
                    </ButtonFilled>
                    <TextSub>
                      Or choose other tournaments <Link to={'https://tradingbattles.com/#upcoming-tournaments'}>here</Link>
                    </TextSub>
                  </>
                ) :
                  <>
                    <ButtonFilled type="submit" onClick={() => setIsOpenedModal(!isOpenedModal)}>
                      Join
                    </ButtonFilled>
                    <TextSub>
                      Or choose other tournaments <Link to={'https://tradingbattles.com/#upcoming-tournaments'}>here</Link>
                    </TextSub>
                  </>
                }
              </div>

            }

          </>
        ) : null}
      </RowWrap>

      {!joinedStatus && (tournamentStatus === 'IN_PROGRESS' || tournamentStatus === 'BEFORE_START') ? (
        <>
          {isOpenedPopup && (
            <ModalSimple>
              <ModalBackground onClick={() => setIsOpenedPopup(!isOpenedPopup)} />
              <ContentModalSimple>
                <ClosePopup onClick={() => setIsOpenedPopup(!isOpenedPopup)} />
                <a href="https://blackfort.exchange/" target="_blank" rel="noreferrer">
                  <img src={PopupImg} alt="Img Popup" />
                </a>
              </ContentModalSimple>
            </ModalSimple>
          )}
        </>
      ) : null}

      {isOpenedModal && (
        <Modal title="Join tournament">
          <CloseModal onClick={() => setIsOpenedModal(!isOpenedModal)} />
          <TermConsent />
          <ContentCheckbox>
            <div>
              <RowCheckbox>
                <label htmlFor="checkbox1" className="option">
                  <p>I accept <Link target="_blank" to={'https://tradingbattles.com/terms-of-use/'}>Terms of Use</Link> of Trading Battles</p>
                  <input ref={ref1} type="checkbox" id="checkbox1" onChange={checkboxHandler} />
                  <span className="checkbox checkboxStyle"></span>
                </label>
                <label htmlFor="checkbox2" className="option">
                  <p>I accept <Link target="_blank" to={'https://tradingbattles.com/terms-and-conditions/'}>Terms & Conditions</Link> of Trading Battles</p>
                  <input ref={ref2} type="checkbox" id="checkbox2" onChange={checkboxHandler} />
                  <span className="checkbox checkboxStyle"></span>
                </label>
                <label htmlFor="checkbox3" className="option">
                  <p>I accept <Link target="_blank" to={'https://tradingbattles.com/privacy-policy/'}>Privacy Policy</Link> of Trading Battles</p>
                  <input ref={ref3} type="checkbox" id="checkbox3" onChange={checkboxHandler} />
                  <span className="checkbox checkboxStyle"></span>
                </label>
              </RowCheckbox>
            </div>
            <ButtonFilled disabled={!agree} type="submit" onClick={handleJoinClick}>
              {isload ? <Load><LoadWrap className="button_load"></LoadWrap></Load> : "Ok"}
            </ButtonFilled>
          </ContentCheckbox>
        </Modal>
      )}
    </div>
  );
};
export default JoinPage;
