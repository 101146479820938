import styled from 'styles/styled';

export const PopupWrap = styled.div`
  position: absolute;
  top: 28px;
  min-width: 170px;
  border: 1px solid #696969;
  background-color: #000;
  padding: 10px 12px 3px 12px;
  border-radius: 12px;
  z-index: 100;
  &:after {
    content: " ";
    width: 12px;
    height: 13px;
    background-color: #000;
    position: absolute;
    top: -7px;
    border-top: 1px solid #696969;
    border-left: 1px solid #696969;
    transform: rotate(45deg);
    right: 0;
    left: 0;
    margin: 0 auto;
  }
  &.LargePopup {
    min-width: 220px;
  }
  p {
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    color: #efefef;
    margin-bottom: 10px;
  }
`;

export const CloseWrap = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.3;
  top: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  z-index: -1;
`;
