import styled from 'styles/styled';
import photoChart from '../../../assets/Chart.png';
import logoImg from '../../../assets/LargeLogo.svg';
import bullMobile from '../../../assets/PageDetail/bull_mobile.png';
import bearMobile from '../../../assets/PageDetail/bear_mobile.png';
import photoDesktop from '../../../assets/fullbodyJoin.png';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    min-width: 200px;
  }
`;

export const LogoTB = styled.div`
  width: 159.25px;
  height: 118.46px;
  background-image: url(${logoImg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 28px auto 0 auto;
  position: relative;
  z-index: 10;
  display: none;
  @media (max-width: 720px) {
    display: block;;
  }
`;

export const Photo = styled.div`
  min-height: 200px;
  background-size: contain;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  height: calc(100vh - 500px);
  max-height: 700px;
  margin-bottom: 50px;
  position: relative;
  background-image: url(${photoDesktop});
  background-size: contain;

  @media (max-width: 720px) {
    background-image: url(${photoChart});
    background-size: contain;
    height: calc(100vh - 335px);
    height: calc(100svh - 335px);
    min-height: 300px;
    max-height: 380px;
  }
  &:after {
    @media (max-width: 720px) {
      content: " ";
      position: absolute;
      height: 300px;
      width: 180px;
      background-size: contain;
      background-position: left bottom;
      background-repeat: no-repeat;
      left: 0;
      max-width: 55%;
      bottom: -20px;
      background-image: url(${bullMobile});
    }
  }
  &:before {
    @media (max-width: 720px) {
      content: " ";
      position: absolute;
      height: 220px;
      width: 155px;
      max-width: 40%;
      background-size: contain;
      background-position: right bottom;
      background-repeat: no-repeat;
      right: 0;
      bottom: -20px;
      background-image: url(${bearMobile});
    }
  }
`;

export const LoadTournament = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  min-height: calc(100vh - 85px);
  min-height: calc(100svh - 85px);
  background: linear-gradient(180deg, #000000 0%, #000000 6.89%, #000000 35.39%, #15191E 100%);
  border-radius: 10px;
  @media (max-width: 1020px) {
    min-height: calc(100vh - 105px);
    min-height: calc(100svh - 105px);
  }
  @media (max-width: 720px) {
    padding: 0;
    justify-content: flex-start;
  }
`;
