import styled from 'styles/styled';
import imgCount from '../../../assets/countBG.svg';

export const Wrapper = styled.div`
`;

export const Count = styled.h4`
  font-size: 55px;
  font-weight: 700;
  line-height: 120px;
  text-align: center;
  color: #000;
  max-width: 390px;
  width: 100%;
  background-image: url(${imgCount});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 120px;
  min-width: 390px;
  margin-top: -30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Row = styled.div`
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const SubTitle = styled.h4`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 24px;
  color: #EFEFEF;
  @media (max-width: 720px) {
    font-size: 16px;
  }
`;

export const SubTitleLarge = styled.h3`
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  color: #EFEFEF;
  text-align: center;
  margin: 0 0 25px 0;
  span {
    color: #52D381;
  }
`;

export const ErrorInfo = styled.h4`
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  margin: 0 0 25px 0;
  color: #FF4B4A;
`;

export const LoadAnime = styled.div`
  display: block;
  width: 52px;
  height: 52px;
  animation: rotation 800ms infinite linear;
  border-radius: 100%;
  margin-bottom: 10px;
  box-shadow: 1px 1px 0 1px #52d382;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
