import styled from 'styles/styled';

export const Wrap = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;


export const WrapCenter = styled.div`
  background-color: #15191E;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  min-width: 390px;
  min-height: 300px;
  margin: 0 auto;
  @media (max-width: 720px) {
    min-width: initial;
    width: 380px;
    max-width: calc(100% - 80px);
  }
  h2 {
    margin-bottom: 6px;
  }
  p {
    margin-bottom: 43px;
  }
`;
