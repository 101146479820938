import React, { useState, useEffect } from 'react';
import { ButtonFilled } from 'components/ui/Button';
import Cookies from 'js-cookie';
import ModalSmallInfoBase from 'components/ui/ModalSmallInfoBase';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import PopupInfo from 'components/CreatingTournament/PopupInfo';

import {
  Wrap,
  Title,
  TextBold,
  Content,
  Text,
  Row,
  Col,
  ColMaxW,
  IconCalendar,
  Close,
  CalendarWrap,
  RowText,
  IconInfo,
} from './styled';

import { useUser } from 'api/userService';
import { registerAnonymousUser } from 'utils/auth/anonymousRegistration';
import { useNavigate } from 'react-router-dom';
import * as routes from 'constants/routes';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

type TournamentType = '1vs1' | 'multiplayer';
type TradeType = 'Spot' | 'Margin';
type GameDuration = '5 min' | '30 min' | '1h' | '12h' | '1d' | '7d';

interface TournamentCreatingProps {
  onCreateTournament: (data: {
    tournament_type: TournamentType;
    trading_assets: string;
    trade_type: TradeType;
    game_duration: GameDuration;
    start_at: string;
    allow_anonymous_users?: boolean;
  }) => void;
  setIsOpenedViewLoad: (isLoading: boolean) => void;
}

interface FormData {
  tournament_type: TournamentType;
  trading_assets: string;
  trade_type: TradeType;
  game_duration: GameDuration;
  start_type: 'now' | 'scheduled';
  allow_anonymous_users?: boolean;
}

const TournamentCreating: React.FC<TournamentCreatingProps> = ({
  onCreateTournament,
  setIsOpenedViewLoad
}) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useUser();
  const [startDate, setStartDate] = useState<Value>(new Date());
  const minDate = new Date();
  const [isOpenedModalDate, setIsOpenedModalDate] = useState(false);
  const username = localStorage.getItem('username') ?? Cookies.get('username');
  const [isSelectedDate, setSelectedDate] = useState(true);

  const [showPopup, setShowPopup] = useState(false)
  const [showPopup1, setShowPopup1] = useState(false)
  const [showPopup2, setShowPopup2] = useState(false)
  const [showPopup3, setShowPopup3] = useState(false)

  const handleClose = () => {setShowPopup(false)}
  const handleClose1 = () => {setShowPopup1(false)}
  const handleClose2 = () => {setShowPopup2(false)}
  const handleClose3 = () => {setShowPopup3(false)}

  const [formData, setFormData] = useState<FormData>({
    tournament_type: '1vs1',
    trading_assets: 'all',
    trade_type: 'Spot',
    game_duration: '1h',
    start_type: 'now',
    allow_anonymous_users: true
  });

  useEffect(() => {
    if (formData.tournament_type === 'multiplayer') {
      const tournamentData = {
        ...formData,
        start_at: formData.start_type === 'now'
          ? new Date().toISOString()
          : startDate instanceof Date
            ? startDate.toISOString()
            : new Date().toISOString()
      };
      localStorage.setItem('pendingTournamentData', JSON.stringify(tournamentData));
    }
  }, [formData, startDate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value as any,
      ...(name === 'tournament_type' && {
        allow_anonymous_users: value === '1vs1'
      })
    }));
  };

  const handleSubmit = async () => {
    if (formData.tournament_type === 'multiplayer' && !isLoggedIn) {
      const tournamentData = {
        ...formData,
        start_at: formData.start_type === 'now'
          ? new Date().toISOString()
          : startDate instanceof Date
            ? startDate.toISOString()
            : new Date().toISOString()
      };
      localStorage.setItem('pendingTournamentData', JSON.stringify(tournamentData));
      navigate(routes.HOME);
      return;
    }

    setIsOpenedViewLoad(true);

    try {
      if (formData.tournament_type === '1vs1' && !isLoggedIn) {
        const credentials = await registerAnonymousUser();
        localStorage.setItem('username', credentials.username);
        localStorage.setItem('email', credentials.email);
      }

      const tournamentData = {
        ...formData,
        allow_anonymous_users: formData.tournament_type === '1vs1',
        start_at: formData.start_type === 'now'
          ? new Date().toISOString()
          : startDate instanceof Date
            ? startDate.toISOString()
            : new Date().toISOString()
      };

      onCreateTournament(tournamentData);
    } catch (error) {
      console.error('Error creating tournament:', error);
      setIsOpenedViewLoad(false);
      alert('An error occurred while creating the tournament. Please try again.');
    }
  };

  const showModalDate = () => {
    setSelectedDate(true);
    setIsOpenedModalDate(true);
    setFormData(prev => ({ ...prev, start_type: 'scheduled' }));
  };

  const closePopUp = () => {
    setIsOpenedModalDate(false);
  };

  const handleDateChange = (date: Value) => {
    setStartDate(date);
  };

  return (
    <>
      <Wrap>
        <Title>Game parameters</Title>
        <TextBold>Organizer: <span>{username}</span></TextBold>
        <Content>
          <RowText>
            <Text>Type of the game<IconInfo onClick={() => setShowPopup(true)} /></Text>
            {showPopup &&
              <PopupInfo
                className="smallPopup"
                text="1vs1 game: Only 2 players – you and someone you invite via link or QR code"
                subText="Multiplayer game: Open tournament with unlimited spots for all to join. Access via Trading Battles website, link or QR code"
                subTextSecond=""
                handleClose={handleClose}
              />
            }
          </RowText>
          <Row>
            <Col>
              <input
                type="radio"
                name="tournament_type"
                value="1vs1"
                id="1vs1"
                checked={formData.tournament_type === '1vs1'}
                onChange={handleInputChange}
              />
              <label htmlFor="1vs1">1vs1</label>
            </Col>
            <Col>
              <input
                type="radio"
                name="tournament_type"
                value="multiplayer"
                id="multiplayer"
                checked={formData.tournament_type === 'multiplayer'}
                onChange={handleInputChange}
              />
              <label htmlFor="multiplayer">Multiplayer</label>
            </Col>
          </Row>

          <RowText>
            <Text>Trading assets<IconInfo onClick={() => setShowPopup1(true)} /></Text>
            {showPopup1 &&
              <PopupInfo
                className="smallPopup"
                text="Choose assets for your game:"
                subText="BTC/USDT: Only Bitcoin "
                subTextSecond="TOP 50 Crypto: 50 largest cryptocurrencies by market cap, traded against USDT"
                handleClose={handleClose1}
              />
            }
          </RowText>

          <Row>
            <Col>
              <input
                type="radio"
                name="trading_assets"
                value="all"
                id="all"
                checked={formData.trading_assets === 'all'}
                onChange={handleInputChange}
              />
              <label htmlFor="all">All</label>
            </Col>
            <Col>
              <input
                type="radio"
                name="trading_assets"
                value="BTC/USDT"
                id="btc"
                checked={formData.trading_assets === 'BTC/USDT'}
                onChange={handleInputChange}
              />
              <label htmlFor="btc">BTC/USDT</label>
            </Col>
          </Row>

          <RowText>
            <Text>Trade type<IconInfo onClick={() => setShowPopup2(true)} /></Text>
            {showPopup2 &&
              <PopupInfo
                className="smallPopup"
                text="Choose assets for your game:"
                subText="Spot: Trading with no leverage"
                subTextSecond="Margin: Trading with leverage (x1, x2, x3, x5, x10), allowing for larger positions and higher potential gains or losses"
                handleClose={handleClose2}
              />
            }
          </RowText>
          <Row>
            <Col>
              <input
                type="radio"
                name="trade_type"
                value="Spot"
                id="spot"
                checked={formData.trade_type === 'Spot'}
                onChange={handleInputChange}
              />
              <label htmlFor="spot">Spot</label>
            </Col>
            <Col>
              <input
                type="radio"
                name="trade_type"
                value="Margin"
                id="margin"
                checked={formData.trade_type === 'Margin'}
                onChange={handleInputChange}
              />
              <label htmlFor="margin">Margin</label>
            </Col>
          </Row>

          <RowText>
            <Text>Game duration</Text>
          </RowText>
          <Row className="RowLarge">
            {(['5 min', '30 min', '1h', '12h', '1d', '7d'] as const).map((duration) => (
              <ColMaxW key={duration}>
                <input
                  type="radio"
                  name="game_duration"
                  value={duration}
                  id={duration}
                  checked={formData.game_duration === duration}
                  onChange={handleInputChange}
                />
                <label htmlFor={duration}>{duration}</label>
              </ColMaxW>
            ))}
          </Row>

          <RowText>
            <Text>Start<IconInfo onClick={() => setShowPopup3(true)} /></Text>
            {showPopup3 &&
              <PopupInfo
                className="LargePopup"
                text="Choose when you want to start your game:"
                subText="NOW: 1vs1 – Game starts as soon as your opponent joins via QR code or link Multiplayer – Game starts instantly "
                subTextSecond="Choose Date: Multiplayer – Set a start date for the game, ideal for sharing and inviting others to your tournament"
                handleClose={handleClose3}
              />
            }
          </RowText>
          <Row>
            <Col className="colSmall">
              <input
                type="radio"
                name="start_type"
                value="now"
                id="now"
                checked={formData.start_type === 'now'}
                onChange={handleInputChange}
              />
              <label htmlFor="now" onClick={() => setSelectedDate(false)}>Now</label>
            </Col>
            <Col className="colLarge">
              <input
                type="radio"
                name="start_type"
                value="scheduled"
                id="scheduled"
                checked={formData.start_type === 'scheduled'}
                onChange={handleInputChange}
              />
              <label htmlFor="scheduled" onClick={showModalDate}>
                <IconCalendar/> Choose date
              </label>
            </Col>
          </Row>
        </Content>

        {isSelectedDate && (
          <Text className="inner">
            {minDate < startDate &&
              <>Start: {startDate instanceof Date ? startDate.toLocaleString() : 'Not selected'}</>
            }
          </Text>
        )}

        <ButtonFilled className="btnSubmit" onClick={handleSubmit}>Create</ButtonFilled>
      </Wrap>

      {isOpenedModalDate && (
        <ModalSmallInfoBase>
          <Close onClick={closePopUp}/>
          <CalendarWrap>
            <Title>Choose date</Title>
            <Text>when your game starts</Text>
            {isSelectedDate && (
              <Text className="inner">
                {minDate < startDate &&
                  <>Selected date: {startDate instanceof Date ? startDate.toLocaleString() : 'Not selected'}</>
                }
              </Text>
            )}
            <Calendar
              onChange={handleDateChange}
              value={startDate}
              minDate={minDate}
            />
            <ButtonFilled className="center" onClick={closePopUp}>Accept</ButtonFilled>
          </CalendarWrap>
        </ModalSmallInfoBase>
      )}
    </>
  );
};

export default TournamentCreating;
